import styled from "styled-components";

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 500px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 1;
  background: #ffffff;
`;

Header.BackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  width: 10%;
`;

Header.Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: bold;
`;
