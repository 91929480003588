import React, { useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import jsCookie from 'js-cookie';
import * as ROUTES from './constants/routes';
import { IsUserRedirect, ProtectedRoute } from './helpers/routes';
import { useAuthListener } from './hooks';
import { Spinner } from './Components';
import { StoreInfoContext } from './contexts/store';
import { RestaurantsInfoContext } from './contexts/restaurantInfo';
import './app.scss';
import './assets/scss/dashboardStyle.scss';
import 'react-intl-tel-input/dist/main.css';
import 'react-toastify/dist/ReactToastify.css';
import { SubPagesInfoContext } from './contexts/subPagesInfo';
import ScrollToTop from './helpers/ScrollToTop';

const Login = React.lazy(() => import('./pages/login/Login'));
const Onboard = React.lazy(() => import('./pages/Onboard/Onboard'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const TheLayout = React.lazy(() => import('./containers/TheLayout/TheLayout'));

export default function App() {
  const [isNewUser, setIsNewUser] = useState();
  const { user } = useAuthListener();
  const [ownerId, setOwnerId] = useState();
  // Set default Cookie || For development purpose only
  if (process.env.REACT_APP_GIP_USERID_COOKIE) {
    jsCookie.set('gipuserid', process.env.REACT_APP_GIP_USERID_COOKIE);
  }

  if (window.location.reload) {
    sessionStorage.removeItem('editOfferCode');
    sessionStorage.removeItem('copiedCode');
    sessionStorage.removeItem('userid');
  }

  var initial_url = window.location.href;
  var url = initial_url.split('/');
  if (url[3].includes('GS')) {
    sessionStorage.setItem('userType', 'Merchant');
  } else {
    sessionStorage.setItem('userType', 'Consumer');
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <React.Suspense fallback={Spinner}>
        <RestaurantsInfoContext>
          <SubPagesInfoContext user={user}>
            <Switch>
              <ProtectedRoute exact user={user} path={ROUTES.PASTORDER}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              {/* <ProtectedRoute exact user={user} path={ROUTES.DELIVERY_DASHBOARD}>
              <StoreInfoContext user={user}>
                <TheLayout />
              </StoreInfoContext>
            </ProtectedRoute> */}
              <ProtectedRoute exact user={user} path={ROUTES.NEW_DASHBOARD}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              {/* <ProtectedRoute exact user={user} path={ROUTES.TAKEAWAY_DASHBOARD}>
              <StoreInfoContext user={user}>
                <TheLayout />
              </StoreInfoContext>
            </ProtectedRoute> */}
              <ProtectedRoute exact user={user} path={ROUTES.OFFERS}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.INVENTORY}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.ORDERS_RESPONSIVE}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute
                exact
                user={user}
                path={ROUTES.ORDERDETAILS_RESPONSIVE}
              >
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>

              <ProtectedRoute exact user={user} path={ROUTES.RECENT_PRODS}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              {/* <ProtectedRoute exact user={user} path={ROUTES.ANALYTICS}>
              <StoreInfoContext user={user}>
                <TheLayout />
              </StoreInfoContext>
            </ProtectedRoute> */}
              <ProtectedRoute exact user={user} path={ROUTES.ADD_NEW_PRODUCT}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.QUICK_ADD}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.ADD_NEW_CATEGORY}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.SIGNUP}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.SETTINGS}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.DASHBOARD}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.ONBOARD}>
                <Onboard ownerId={ownerId} />
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.FAQ}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <IsUserRedirect
                exact
                user={user}
                isNewUser={isNewUser}
                newUserPath={ROUTES.ONBOARD}
                loggedInPath={ROUTES.DASHBOARD}
                path={ROUTES.Login}
              >
                <Login setOwnerId={setOwnerId} setIsNewUser={setIsNewUser} />
              </IsUserRedirect>

              {/* <Route exact path={ROUTES.ORDERCONFIRMATION} render={() => <OrderConfirmation />}/> */}
              <Route
                exact
                path={ROUTES.ERROR500}
                name='Page 500'
                render={() => <Page500 />}
              />
              <Route
                path={ROUTES.ERROR404}
                name='Page 404'
                render={() => <Page404 />}
              />
              <Route name='Page 404' render={() => <Page404 />} />
            </Switch>
          </SubPagesInfoContext>
        </RestaurantsInfoContext>
        <ToastContainer
          className={
            sessionStorage.getItem('userType') === 'Merchant'
              ? 'merchantType'
              : ''
          }
        />
      </React.Suspense>
    </BrowserRouter>
  );
}
