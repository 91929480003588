import axios from 'axios';
import jsCookie from 'js-cookie';
import i18n from 'i18next';
import { Notification } from '../helpers/notification/notification';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers = {
  'Content-Type': 'application/json',
  gipuserid: jsCookie.get('gipuserid'),
};

const t = i18n.getFixedT(null, 'common');

const handleError = (error) => {
  if (error && error.response) {
    let displayMsg = null;
    if (error.response.message) {
      displayMsg = error.response.message;
    } else if (error.response.data) {
      if (error.response.data.message) {
        displayMsg = error.response.data.message;
      } else if (error.response.data.errors && error.response.data.errors[0]) {
        displayMsg = error.response.data.errors[0].error;
      } else if (error && error.response.data.err) {
        displayMsg = error.response.data.err;
      } else if (error && error.response.data.error) {
        displayMsg = error.response.data.error;
      }
    } else if (error && error.response.statusText) {
      displayMsg = error.response.statusText;
    }
    if (displayMsg) {
      Notification({
        type: 'error',
        displayMessage: t(displayMsg),
        toasterOptions: {
          toastId: displayMsg,
        },
      });
    }
  } else if (error && error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    Notification({
      type: 'error',
      displayMessage:
        error.request.status === 0
          ? 'Please check your internet connection!'
          : 'Something went wrong. Try again',
      toasterOptions: {
        toastId: 'connectionError',
      },
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    Notification({
      type: 'error',
      displayMessage: error.message,
      toasterOptions: {
        toastId: error.message,
      },
    });
  }
};

function GET(url, headers = null) {
  axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
  if (headers && headers.Authorization) {
    axios.defaults.headers['Authorization'] = headers.Authorization;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => resolve(response))
      .catch(function (error) {
        handleError(error);
        reject(error);
      });
  });
}
function POST(url, data = {}, headers = null) {
  return new Promise((resolve, reject) => {
    // convert to string if it's object
    try {
      if (typeof data !== 'string') {
        data = JSON.stringify(data);
      }
    } catch (error) {
      reject(error);
    }

    if (headers && headers.Authorization) {
      axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios
      .post(url, data, headers)
      .then((response) => resolve(response))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

function POSTIMAGE(url, data = {}, headers = null) {
  return new Promise((resolve, reject) => {
    if (headers && headers.Authorization) {
      axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios
      .post(url, data, headers)
      .then((response) => resolve(response))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

function DELETE(url) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((response) => resolve(response))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}
function PUT(url, data, headers = null) {
  return new Promise((resolve, reject) => {
    // convert to string if it's object
    try {
      if (typeof data !== 'string') {
        data = JSON.stringify(data);
      }
    } catch (error) {
      reject(error);
    }

    if (headers && headers.Authorization) {
      axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios
      .put(url, data, headers)
      .then((response) => resolve(response))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

function PUTIMAGE(url, data, headers = null) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then((response) => resolve(response))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}
export { GET, POST, DELETE, PUT, POSTIMAGE, PUTIMAGE };
