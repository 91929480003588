import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../contexts/firebase';
import jsCookie from 'js-cookie';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

export function useAuthListener() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('authUser'))
  );
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        //console.log(authUser);
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser(authUser);
      } else {
        localStorage.removeItem('authUser');
        setUser(null);
      }
    });

    return () => listener();
  }, [firebase]);

  return { user };
}

export function useCookieAuthListener() {
  const [cookie, setCookie] = useStateWithCallbackLazy(
    jsCookie.get('gipuserid')
  );

  useEffect(() => {
    if (jsCookie.get('gipuserid')) {
      setCookie(jsCookie.get('gipuserid'));
    } else {
      setCookie(null);
    }
  }, []);

  return { cookie, setCookie };
}
