export const Login = '/';
export const ONBOARD = '/onboard';
export const DASHBOARD = '/dashboard';
export const PASTORDER = '/pastOrders';
export const DELIVERY_DASHBOARD = '/delivery';
export const TAKEAWAY_DASHBOARD = '/takeaway';
export const INVENTORY = '/inventory';
export const SIGNUP = '/signup';
export const SETTINGS = '/settings';
export const OFFERS = '/offers';
export const ADD_NEW_PRODUCT = '/addNewProduct';
export const QUICK_ADD = '/quickadd';
export const ADD_NEW_CATEGORY = '/addNewCategory';
export const ORDERS_RESPONSIVE = '/ordersResponsive';
export const ORDERDETAILS_RESPONSIVE = '/orderDetailsResponsive';
export const ANALYTICS = '/analytics';
export const NEW_DASHBOARD = '/orders';
export const RECENT_PRODS = '/recentProduct';
export const ERROR404 = '/error/404';
export const ERROR500 = '/error/500';
export const FAQ = '/FAQ';
