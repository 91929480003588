import React, { createContext, useContext, useEffect, useState } from 'react';
import { GlobalStyles } from '../global-styles';
import { getOnboardDetails } from '../utils/API';

export const SubPagesInfo = createContext(null);
export const SubPagesInfoContext = ({ user, children }) => {
  const [currentSubPage, setCurrentSubPage] = useState();
  const [createOfferFlag, setCreateOfferFlag] = useState(true);
  const [createDealFlag, setCreateDealFlag] = useState(true);
  const [showPage, setPage] = useState(false);
  const [showAccSettingsPage, setAccSettingsPage] = useState(false);
  const [showAppSettingsPage, setAppSettingsPage] = useState(false);
  const [showOrderSettingsPage, setOrderSettingsPage] = useState(false);
  const [showTrack, setTrack] = useState(false);
  const [editItemPopUp, setEditItemPopUp] = useState(false);
  const [editItemPopUp1, setEditItemPopUp1] = useState(false);
  const [editCategoryPopUpShow, setEditCategoryPopUpShow] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openSearchCatalogue, setOpenSearchCatalogue] = useState(false);
  const [recentItemsLength, setRecentItemsLength] = useState(0);
  const [openSearchOrder, setOpenSearchOrder] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOnboardProcessComplete, setOnboardProcessComplete] = useState(true);
  const pageMap = {
    coupon: { get: createOfferFlag, set: setCreateOfferFlag },
    deal: { get: createDealFlag, set: setCreateDealFlag },
    order: { get: showPage, set: setPage },
    accountSettings: { get: showAccSettingsPage, set: setAccSettingsPage },
    appSettings: { get: showAppSettingsPage, set: setAppSettingsPage },
    orderSettings: { get: showOrderSettingsPage, set: setOrderSettingsPage },
    track: { get: showTrack, set: setTrack },
    editItemPopUp: { get: editItemPopUp, set: setEditItemPopUp },
    editCategoryPopUpShow: {
      get: editCategoryPopUpShow,
      set: setEditCategoryPopUpShow,
    },
    openSearch: { get: openSearch, set: setOpenSearch },
    openSearchCatalogue: {
      get: openSearchCatalogue,
      set: setOpenSearchCatalogue,
    },
    editItemPopUp1: { get: editItemPopUp1, set: setEditItemPopUp1 },
    openSearchOrder: {
      get: openSearchOrder,
      set: setOpenSearchOrder,
    },
    chooseYourLocation: { get: showModal, set: setShowModal },
  };

  useEffect(() => {
    if (user) {
      let menu_id = sessionStorage.getItem('currentStoreId');
      getOnboardDetails({
        ownerId: user.email ? user.email : user.phoneNumber,
        menu_id: menu_id,
      })
        .then((result) => {
          if (result.redirectStep === 3) {
            setOnboardProcessComplete(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  return (
    <SubPagesInfo.Provider
      value={{
        currentSubPage,
        setCurrentSubPage,
        createOfferFlag,
        setCreateOfferFlag,
        createDealFlag,
        setCreateDealFlag,
        showPage,
        setPage,
        showAccSettingsPage,
        setAccSettingsPage,
        showAppSettingsPage,
        setAppSettingsPage,
        showOrderSettingsPage,
        setOrderSettingsPage,
        showTrack,
        setTrack,
        pageMap,
        editItemPopUp,
        setEditItemPopUp,
        editCategoryPopUpShow,
        setEditCategoryPopUpShow,
        openSearch,
        setOpenSearch,
        openSearchCatalogue,
        setOpenSearchCatalogue,
        recentItemsLength,
        setRecentItemsLength,
        editItemPopUp1,
        setEditItemPopUp1,
        openSearchOrder,
        setOpenSearchOrder,
        showModal,
        setShowModal,
        isOnboardProcessComplete,
        setOnboardProcessComplete,
      }}
    >
      <GlobalStyles currentSubPage={currentSubPage} />
      {children}
    </SubPagesInfo.Provider>
  );
};

export const useSubPageInfoContext = () => {
  const {
    currentSubPage,
    setCurrentSubPage,
    createOfferFlag,
    setCreateOfferFlag,
    createDealFlag,
    setCreateDealFlag,
    showPage,
    setPage,
    showAccSettingsPage,
    setAccSettingsPage,
    showAppSettingsPage,
    setAppSettingsPage,
    showOrderSettingsPage,
    setOrderSettingsPage,
    showTrack,
    setTrack,
    pageMap,
    editItemPopUp,
    setEditItemPopUp,
    editCategoryPopUpShow,
    setEditCategoryPopUpShow,
    openSearch,
    setOpenSearch,
    openSearchCatalogue,
    setOpenSearchCatalogue,
    recentItemsLength,
    setRecentItemsLength,
    editItemPopUp1,
    setEditItemPopUp1,
    openSearchOrder,
    setOpenSearchOrder,
    showModal,
    setShowModal,
    isOnboardProcessComplete,
    setOnboardProcessComplete,
  } = useContext(SubPagesInfo);
  return {
    currentSubPage,
    setCurrentSubPage,
    createOfferFlag,
    setCreateOfferFlag,
    createDealFlag,
    setCreateDealFlag,
    showPage,
    setPage,
    showAccSettingsPage,
    setAccSettingsPage,
    showAppSettingsPage,
    setAppSettingsPage,
    showOrderSettingsPage,
    setOrderSettingsPage,
    showTrack,
    setTrack,
    pageMap,
    editItemPopUp,
    setEditItemPopUp,
    editCategoryPopUpShow,
    setEditCategoryPopUpShow,
    openSearch,
    setOpenSearch,
    openSearchCatalogue,
    setOpenSearchCatalogue,
    recentItemsLength,
    setRecentItemsLength,
    editItemPopUp1,
    setEditItemPopUp1,
    openSearchOrder,
    setOpenSearchOrder,
    showModal,
    setShowModal,
    isOnboardProcessComplete,
    setOnboardProcessComplete,
  };
};
