import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Login, ONBOARD } from '../constants/routes';
import { useSubPageInfoContext } from '../contexts/subPagesInfo';
export function IsUserRedirect({
  user,
  isNewUser,
  newUserPath,
  loggedInPath,
  children,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          return children;
        }

        if (user) {
          if (isNewUser) {
            return (
              <Redirect
                to={{
                  pathname: newUserPath,
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: loggedInPath,
                }}
              />
            );
          }
        }

        return null;
      }}
    />
  );
}

export function ProtectedRoute({ user, children, ...rest }) {
  const { isOnboardProcessComplete } = useSubPageInfoContext();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          if (!isOnboardProcessComplete) {
            if (location.pathname === ONBOARD) {
              return children;
            }
            return (
              <Redirect
                to={{
                  pathname: ONBOARD,
                  state: { from: location },
                }}
              />
            );
          } else {
            return children;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: Login,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export function CookieProtectedRoute({ cookie, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (cookie) {
          return children;
        } else {
          return (window.location = `https://gipw.gupshup.io/is/?redirectUrl=${window.location.href}`);
        }
      }}
    />
  );
}
