import { createGlobalStyle } from 'styled-components';
import './app.scss';
import { isMobileOrTablet } from './utils/common';

function getContrastYIQ(hexcolor, defaultColor) {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : defaultColor;
}
export const GlobalStyles = createGlobalStyle`

:root {

/* Colors: */
--default-theme-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultThemeColor || '#B04040'};
--default-text-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultTextColor || '#103675'};
--default-button-text-color: ${(props) =>
  getContrastYIQ(
    props?.restaurant?.meta_data?.defaultThemeColor || '#B04040',
    props?.restaurant?.meta_data?.buttonTextColor || '#F8F8F8'
  )};
--default-backdrop-color: ${(props) =>
  props?.restaurant?.meta_data?.defaultBackdropColor || '#F8CCCC'};;
--color-grey: #343434;
--color-ae5151: #AE5151;
--list-background-color: #f3f3f3;
--primary-background-color: #FFFFFF;
--instructions-added-icon-color: #5ACB10;
--default-button-border-radius: 6px;
--font-weight-100: 100;
--font-weight-300: 300;
--font-weight-500: 500;
--font-weight-700: 700;
--default-font-family: 'Open Sans', sans-serif; 
--default-font-family-dashboard: 'Poppins', sans-serif;
}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    font-family: var(--default-font-family-dashboard) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFFFFF;
    color: #4b4b4b;
    font-size: 16px;
}

input {
  @supports (-webkit-touch-callout: none) {
    font-size: 16px;
  }
}
  .gmnoprint {
    display: none !important;
  }
  .Toastify__toast-container {
    padding: 10px;
  }
  .grecaptcha-badge {
    position: fixed !important;
    left: -1000px !important;
    z-index: -9;
  }
  .form-control:invalid, .form-control.is-invalid {
    background-image: none !important;
  }
  .authModal {
    .modal-content {
      border-radius: 10px !important;
      padding: 20px 20px 20px 30px;
      width: 60em;
      height: 30em;

    }
    @media only screen and (max-width: 320px) {
      margin: 0 auto;
      .modal-content {
        width: 100%;
        padding: 0px;
        height: 100%;
      }
  
      input {
        width: 40px !important;
        height: 40px !important;
        margin-left: -20%;
      }
    }
    @media only screen and (max-width: 600px) and (min-width: 321px) {
      margin: 0 auto;
      .modal-content {
        width: 100%;
        padding: 0px;
        height: 100%;
      }
      input {
        width: 45px !important;
        height: 45px !important;
        margin-left: -25%;
      }
    }
  }
  .addressModal {
    .modal-content {
      border-radius: 10px !important;

    }
  }
  .c-app {
    background-color: #F8F2FF;
  }
  .c-main {
    background-color: ${({ currentSubPage }) =>
      window.location.pathname === '/offers' && !currentSubPage
        ? 'rgb(248, 242, 255)'
        : currentSubPage || window.location.pathname === '/orders'
        ? `#fff !important`
        : `#fff`};
    ${() =>
      isMobileOrTablet()
        ? 'min-height: 92vh; padding-top: 8vh !important;'
        : 'min-height: 90vh'};
  }
.c-sidebar{
  background: transparent linear-gradient(180deg, #5F36E5 0%, #301B73 100%) 0% 0% no-repeat padding-box;
}
.card {
  background-color: #F8F2FF;
}
.card-header {
  background-color: #F8F2FF;
}
.card-footer {
  background-color: #F8F2FF;
}
.nav-tabs .nav-link.active {
  background-color: #F8F2FF; 
}
.table-hover tbody tr:hover {background-color:#F8F2FF}
 
  `;
