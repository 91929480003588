import { DELETE, GET, POST, POSTIMAGE, PUT, PUTIMAGE } from '../lib/axios';
import { fgetUser } from '../lib/Firebase';

async function getUserAddress(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getUserAddress`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function getDeliveryPartner(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getDeliveryPartner`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateOrderType(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateOrderType`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function uploadQuickAddMenu(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/addItem`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateReminderStatus(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateReminderStatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateMetaData(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateMetaData`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updatePhoneNumber(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updatePhoneNumber`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateSocialHandlesSettings(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateSocialHandlesSettings`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateSelfDeliverySettings(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateSelfDeliverySettings`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateShowOffersStatus(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateshowOffersStatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateAddress(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateAddress`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateLogo(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateLogo`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getOfferList(menuId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/listOffers`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUserLoginDetail(ownerId) {
  return new Promise((resolve, reject) => {
    GET(`/ui/v1/${ownerId}/getUserLoginDetail`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateOfferStatus(menu_id, offer_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/updateOfferStatus/${offer_id}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function createOffer(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/createOffer`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function onboardMerchant(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`/ui/v1/createNewEstablishment`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateEstablishment(data, menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`/ui/v1/${menu_id}/updateEstablishment`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function uploadImage(data, imgLink = false) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`/ui/v1/uploadImage${imgLink && '?imageLink=true'}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getSignedURL() {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`/ui/v1/getSignedUrl`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function uploadImageS3(signedURL, data) {
  return new Promise((resolve, reject) => {
    PUTIMAGE(signedURL, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateOffer(menu_id, offer_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/updateOffer/${offer_id}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function deleteOffer(menu_id, offer_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    DELETE(`ui/v1/${menu_id}/deleteOffer/${offer_id}`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateNotifiedOrderStatus(menu_id, offer_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    PUT(`ui/v1/${menu_id}/updateNotifiedOrderStatus/${offer_id}`, '', header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getConfiguration(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getConfiguration`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getVariantPrice(data, storageType) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${storageType}/getVariantsPrice`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getStoreHasItems(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/hasItems`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getMetadata(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getMetadata`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getPhoneNumber(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getPhoneNumber`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function getSelfDelivery(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getSelfDelivery`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUnitAndCategory(menu_id, attr) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/${menu_id}/getUnitAndCategoryData${attr && '?attribute=true'}`,
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function createProdAndCat(info, menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${info}/${menu_id}/create`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function InventoryGetCat(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/getCategoryData?subCat=true`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function InventoryGetItemData(
  menu_id,
  catId,
  subCatId,
  batch,
  size = 10
) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/dashboard/${menu_id}/items/${catId}/${subCatId}/${batch}/${size}`,
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function InventoryGetRecentItemData(menu_id, type, batch = 0, size = 10) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(
      `ui/v1/dashboard/${menu_id}/recentItems/${type}/${batch}/${size}?days=7`,
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function InventoryGetRecentItemDataCount(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/dashboard/${menu_id}/recentItems/getCount?days=7`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateInStockOutStock(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/inventory`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getAccessToken() {
  let token = await fgetUser();
  let header = null;
  if (token) {
    header = { Authorization: 'Bearer ' + token };
  } else {
    token = JSON.parse(localStorage.getItem('authUser'))?.stsTokenManager
      .accessToken;
    header = { Authorization: 'Bearer ' + token };
  }
  return header;
}

async function fetchAccountDetails(menu_id, info) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${info}/${menu_id}/fetch`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getKycStatus(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/kyc/status`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function fundVerificationStatus(menu_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/fundVerificationStatus`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateAccountDetailsStatus(menu_id, info, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${info}/${menu_id}/updateaccountdetailsstatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function uploadContactDetail(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/uploadContactDetail`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getAnalytics(menuId, data, queryString) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/getBigQueryData/${queryString}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const getCategories = (menuId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/dashboard/${menuId}/categories`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getItemsOrVariants = (menuId, dataType, batch, dataLength) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/dashboard/${menuId}/${dataType}/${batch}/${dataLength}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getVariantsCountOrStatus = (menuId, variant_id, info) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/${variant_id}/${info}/getVariantsCount`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

async function checkItemMappedWithDeal(menu_id, item_id) {
  return new Promise((resolve, reject) => {
    GET(`/ui/v1/${menu_id}/deals/check/${item_id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function deleteInventory(menu_id, info, data) {
  return new Promise((resolve, reject) => {
    POST(
      `ui/v1/${menu_id}/delete`, // info = categories  & menu_id = establishment_id
      data //  "category_ids" : ["CAT716d57"]
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const getItemDesc = (est_id, attr, data) => {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${est_id}/${attr}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const readItem = (est_id, data) => {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/items/${est_id}/read?attribute=search_result`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function createDeal(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/deals/createDeal`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateDeal(menu_id, deal_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/deals/${deal_id}/updateDeal`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function deleteDeal(menu_id, deal_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    DELETE(`ui/v1/${menu_id}/deals/${deal_id}/deleteDeal`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getDealList(menuId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/deals/dealList`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getSettingsData(menuId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/getSettingsData`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateSettingsData(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/updateSettingsData`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function setNewAuth(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/configureRoles`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateDealStatus(menu_id, deal_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/deals/${deal_id}/updateDealStatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const checkItemAssociationInDeal = (est_id, item_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${est_id}/deals/checkItemAssociation/${item_id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getItemData = (est_id, itemIds = []) => {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${est_id}/getItems`, { itemIds })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateVariant = async (est_id, variants) => {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/variants/${est_id}/update`, { ...variants }, header)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateUserDetailsInRocketChatBot = async (user_name) => {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`/ui/v1/updateUserDetailsInRocketChatBot`, { user_name }, header)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const payoutReport = (menuId, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getPayoutReport/${menuId}/${startDate}/${endDate}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const checkItemsStatus = async (est_id, payload) => {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${est_id}/checkItemsStatus`, payload, header)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export {
  onboardMerchant,
  uploadImage,
  getSignedURL,
  uploadImageS3,
  getUserAddress,
  getDeliveryPartner,
  updateOrderType,
  getOfferList,
  getDealList,
  updateOfferStatus,
  updateDealStatus,
  getUserLoginDetail,
  createOffer,
  updateOffer,
  deleteOffer,
  createDeal,
  updateDeal,
  deleteDeal,
  updateNotifiedOrderStatus,
  updateReminderStatus,
  getConfiguration,
  updateShowOffersStatus,
  updateSocialHandlesSettings,
  updateMetaData,
  updatePhoneNumber,
  getVariantPrice,
  getMetadata,
  getPhoneNumber,
  getSelfDelivery,
  updateSelfDeliverySettings,
  updateAddress,
  updateLogo,
  uploadQuickAddMenu,
  fetchAccountDetails,
  updateAccountDetailsStatus,
  uploadContactDetail,
  getAnalytics,
  getUnitAndCategory,
  createProdAndCat,
  getCategories,
  getItemsOrVariants,
  getVariantsCountOrStatus,
  getItemDesc,
  readItem,
  getSettingsData,
  updateSettingsData,
  checkItemAssociationInDeal,
  InventoryGetCat,
  InventoryGetItemData,
  updateInStockOutStock,
  checkItemMappedWithDeal,
  deleteInventory,
  getItemData,
  setNewAuth,
  InventoryGetRecentItemData,
  InventoryGetRecentItemDataCount,
  updateVariant,
  getStoreHasItems,
  updateUserDetailsInRocketChatBot,
  fundVerificationStatus,
  payoutReport,
  checkItemsStatus,
  getKycStatus,
  updateEstablishment,
};
