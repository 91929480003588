import { createContext, useContext, useEffect, useState } from 'react';
import { getRestaurantDetails, getMenuDetails } from '../utils/API';
import { ERROR404, ERROR500 } from '../constants/routes';
import { GlobalStyles } from '../global-styles';
import usePersistedState from '../helpers/persistent';
import { BUSINESS_TYPE } from '../constants/variables';
export const RestaurantsInfo = createContext(null);

export const RestaurantsInfoContext = function ({ user, children, ...props }) {
  const [productId, setProductId] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [businessType, setBusinessType] = usePersistedState(
    '',
    productId,
    'btype'
  );
  const [catalogue, setCatalogue] = usePersistedState(
    {},
    productId,
    'catalogue'
  );

  useEffect(() => {
    if (productId) {
      getRestaurantDetails(productId)
        .then((res) => {
          setRestaurant(res);
          setBusinessType(
            res.meta_data?.business_type
              ? res.meta_data?.business_type
              : BUSINESS_TYPE.RETAIL
          );
          // getMenuDetails(productId)
          //   .then((res) => {
          //     setCatalogue(res);
          //   })
          //   .catch((error) => console.log(error));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 500: {
                window.location.href = ERROR500;
                break;
              }
              case 404: {
                window.location.href = ERROR404;
                break;
              }
              default:
                window.location.href = ERROR500;
            }
          }
        });
    }
  }, [productId]);

  return (
    <RestaurantsInfo.Provider
      value={{
        businessType,
        restaurant,
        setProductId,
        catalogue,
      }}
    >
      {/* <GlobalStyles restaurant={restaurant} /> */}
      {children}
    </RestaurantsInfo.Provider>
  );
};
export function useRestaurantsInfoContext() {
  const {
    restaurant,
    productId,
    setProductId,
    setRestaurant,
    catalogue,
    setCatalogue,
    businessType,
    setBusinessType,
  } = useContext(RestaurantsInfo);
  return {
    productId,
    restaurant,
    setProductId,
    setRestaurant,
    catalogue,
    setCatalogue,
    businessType,
    setBusinessType,
  };
}
