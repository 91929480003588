import React from 'react';

export default function usePersistedState(defaultValue, productId, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(productId + '-' + key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.sessionStorage.setItem(productId + '-' + key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export const useDashboardState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};
