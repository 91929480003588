import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDashboardState } from '../helpers/persistent';
import { fstore, frtdb, getBussinessType } from '../lib/Firebase';
import { useHistory } from 'react-router';
import { ONBOARD } from '../constants/routes';
import { Periodoptions } from '../constants/variables';
import { GlobalStyles } from '../global-styles';
import { useTranslation } from 'react-i18next';
import { getSettingsData } from '../utils/dashboardAPI';
export const StoreInfo = createContext(null);

export const StoreInfoContext = ({ user, children }) => {
  const history = useHistory();
  const { productId } = useParams();
  const [storeData, setStoreData] = useState(null);
  const [storeAddress, setStoreAddress] = useState('');
  const [showMobMenu, setShowMobMenu] = useState(false);
  const [ordersCount, setOrdersCount] = useState({
    pendingOrderCount: -1,
    cancelledOrderCount: -1,
  });
  const [reminderNotification, setReminderNotification] = useDashboardState(
    false,
    'reminder'
  );
  const [currentStore, setCurrentStore] = useDashboardState(
    null,
    'currentStore'
  );
  const [currentStoreOrders, setCurrentStoreOrders] = useDashboardState(
    [],
    'orders'
  );
  const [defaultState, setDefaultState] = useDashboardState(
    'ALL',
    'defaultState'
  );
  const [hasOrders, setHasOrders] = useDashboardState(false, 'hasOrders');
  const [hasNewOrders, setHasNewOrders] = useDashboardState(
    false,
    'hasNewOrders'
  );
  const [showViewStore, setShowViewStore] = useDashboardState(
    false,
    'showViewStore'
  );
  const [currentStoreOrderPeriod, setCurrentStoreOrderPeriod] =
    useDashboardState(Periodoptions[0], 'period');

  const [myOrdersPageSearchValue, setMyOrdersPageSearchValue] =
    useDashboardState(null, 'myOrdersSearch');

  const [BusinessType, setBusinessType] = useDashboardState(
    null,
    'businessType'
  );
  const [currencyCode, setCurrencyCode] = useDashboardState(
    null,
    'currencyCode'
  );
  const [isStoreDataLoading, setIsStoreDataLoading] = useState(false);
  const [orderUnsubscriber, setOrderUnsubscriber] = useState();
  const [showChatBot, setShowChatBot] = useState(false);
  const [storeMetaData, setStoreMetaData] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (user) {
      setIsStoreDataLoading(true);
      getUserStoreData(user)
        .then((result) => {
          setStoreData(result);
          if (result.length === 0) {
            history.push(ONBOARD);
          } else {
            setIsStoreDataLoading(false);
            let isStoreAvailable = false;
            for (let i = 0; currentStore && i < result.length; i++) {
              if (result[i]?.storeId === currentStore?.storeId) {
                isStoreAvailable = true;
              }
            }

            if ((!isStoreAvailable || !currentStore) && result[0]) {
              setCurrentStore(result[0]);
            }
            getBussinessType(currentStore?.storeId).then((res) => {
              setBusinessType(res);
            });
          }
        })
        .catch((error) => {
          setIsStoreDataLoading(false);
          console.log(error);
        });
    }
    if (currentStore && currentStore.storeId) {
      getSettingsData(currentStore?.storeId).then((res) => {
        setStoreMetaData(res);
      });
    }
  }, [currentStore]);

  useEffect(() => {
    i18n.changeLanguage(
      storeMetaData?.configuration?.langConfig
        ? storeMetaData?.configuration?.langConfig
        : 'en'
    );
  }, [storeMetaData]);

  return (
    <StoreInfo.Provider
      value={{
        isStoreDataLoading,
        storeData,
        setStoreData,
        storeAddress,
        setStoreAddress,
        currentStore,
        setCurrentStore,
        orderUnsubscriber,
        setOrderUnsubscriber,
        currentStoreOrders,
        setCurrentStoreOrders,
        ordersCount,
        setOrdersCount,
        reminderNotification,
        setReminderNotification,
        BusinessType,
        currentStoreOrderPeriod,
        setCurrentStoreOrderPeriod,
        myOrdersPageSearchValue,
        setMyOrdersPageSearchValue,
        defaultState,
        setDefaultState,
        hasNewOrders,
        hasOrders,
        setHasOrders,
        setHasNewOrders,
        showViewStore,
        setShowViewStore,
        showMobMenu,
        setShowMobMenu,
        showChatBot,
        setShowChatBot,
        storeMetaData,
        currencyCode,
        setCurrencyCode,
      }}
    >
      <GlobalStyles />
      {children}
    </StoreInfo.Provider>
  );
};

function getUserStoreData(user) {
  return new Promise((resolve, reject) => {
    fstore
      .collection('dashboardUsers')
      .doc(getUserProvider(user))
      .collection('restaurantId')
      .get()
      .then((snapshot) => {
        const result = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.storeId = doc.id;
          return data;
        });
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getUserProvider(user) {
  switch (user.providerData[0].providerId) {
    case 'phone':
      return user.phoneNumber;
      break;
    case 'google.com':
      return user.email;
      break;
    case 'facebook':
      return user.phoneNumber;
      break;
    default:
      return user.email;
  }
}

export const useStoreInfoContext = () => {
  const {
    isStoreDataLoading,
    storeData,
    setStoreData,
    storeAddress,
    setStoreAddress,
    currentStore,
    setCurrentStore,
    orderUnsubscriber,
    setOrderUnsubscriber,
    currentStoreOrders,
    setCurrentStoreOrders,
    ordersCount,
    setOrdersCount,
    reminderNotification,
    setReminderNotification,
    BusinessType,
    currentStoreOrderPeriod,
    setCurrentStoreOrderPeriod,
    myOrdersPageSearchValue,
    setMyOrdersPageSearchValue,
    defaultState,
    setDefaultState,
    hasNewOrders,
    hasOrders,
    setHasOrders,
    setHasNewOrders,
    showViewStore,
    setShowViewStore,
    showMobMenu,
    setShowMobMenu,
    showChatBot,
    setShowChatBot,
    storeMetaData,
    currencyCode,
    setCurrencyCode,
  } = useContext(StoreInfo);

  return {
    isStoreDataLoading,
    storeData,
    setStoreData,
    storeAddress,
    setStoreAddress,
    currentStore,
    setCurrentStore,
    orderUnsubscriber,
    setOrderUnsubscriber,
    currentStoreOrders,
    setCurrentStoreOrders,
    ordersCount,
    setOrdersCount,
    reminderNotification,
    setReminderNotification,
    BusinessType,
    currentStoreOrderPeriod,
    setCurrentStoreOrderPeriod,
    myOrdersPageSearchValue,
    setMyOrdersPageSearchValue,
    defaultState,
    setDefaultState,
    hasNewOrders,
    hasOrders,
    setHasOrders,
    setHasNewOrders,
    showViewStore,
    setShowViewStore,
    showMobMenu,
    setShowMobMenu,
    showChatBot,
    setShowChatBot,
    storeMetaData,
    currencyCode,
    setCurrencyCode,
  };
};
