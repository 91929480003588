export const OrderStatus = Object.freeze({
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  ACK: 'acknowledged',
  ACKNOWLEDGED: 'ORDER_PLACED',
  ORDER_CREATED: 'ORDER_CREATED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  ACCEPTED_BY_RESTAURANT: 'ORDER_ACCEPTED',
  ACCEPTED_BY_DP: 'ACCEPTED_BY_DELIVERY_PARTNER',
  PREPARING_ORDER: 'ORDER_IN_PROCESS',
  READY_FOR_DISPATCH: 'READY_FOR_DISPATCH',
  ORDER_PICKED_UP: 'ORDER_DISPATCHED',
  DELIVERED: 'ORDER_DELIVERED',
  CANCELLED_BY_RESTAURANT: 'ORDER_CANCELLED',
  CANCELLED_BY_DP: 'CANCELLED_BY_DELIVERY_PARTNER',
  CANCELLED: 'USER_CANCELLED',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  WAITING: 'Waiting for delivery partner to accept the order',
});

export const OrderStatusDisplay = Object.freeze({
  CONFIRMED: 'Confirmed',
  COMPLETED: 'FulFilled',
  ACK: 'acknowledged',
  ACKNOWLEDGED: 'Order Placed',
  ORDER_CREATED: 'Order Placed',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_CONFIRMED: 'Payment Confirmed',
  ACCEPTED_BY_RESTAURANT: 'Confirmed',
  ACCEPTED_BY_DP: 'Confirmed',
  PREPARING_ORDER: 'Processing',
  READY_FOR_DISPATCH: 'Ready to dispatch',
  ORDER_PICKED_UP: 'Out for Delivery',
  DELIVERED: 'Delivered',
  CANCELLED_BY_RESTAURANT: 'Cancelled',
  CANCELLED_BY_DP: 'Cancelled',
  CANCELLED: 'Cancelled',
  READY_FOR_PICKUP: 'Ready for PickUp',
  ORDER_COMPLETED: 'FulFilled',
  WAITING: 'Waiting for delivery partner to accept the order',
});

export const PaymentMode = Object.freeze({
  ONLINE: 'online',
  CASH: 'cash',
});
export const PaymentStatus = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const OrderType = Object.freeze({
  DELIVERY: 'delivery',
  TAKEAWAY: 'takeaway',
});

export const OrderTypeCheck = Object.freeze({
  DELIVERY: 'Delivery',
  TAKEAWAY: 'Takeaway',
});

export const OrderTypeLabel = Object.freeze({
  DELIVERY: 'Deliver to my home',
  TAKEAWAY: `Pickup from store`,
});

export const Color = Object.freeze({
  defaultColor: '#EE2F37',
});
export const ChargeTypes = Object.freeze({
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
});

export const DeliveryPartner = Object.freeze({
  SELF_DELIVERY: 'self_delivery',
});

export const filterType = Object.freeze({
  FOOD: 'Food',
  BAR: 'Bar',
  VEG: 'Veg',
  NONVEG: 'Non-Veg',
  EGG: 'Egg',
});
export const paymentStatus = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  REFUNDED: 'refunded',
});
export const routeNames = Object.freeze({
  DASHBOARDName: 'Delivery',
  PASTORDERName: 'Past Orders',
  OFFERSName: 'Offers',
  INVENTORYName: 'Inventory',
  SETTINGS: 'Settings',
  SIGNUP: 'Sign Up',
  TAKEAWAY_DASHBOARD: 'Takeaway',
  DELIVERY_DASHBOARD: 'Delivery',
  ANALYTICS: 'Analytics',
  ORDERS: 'Orders',
  RECENT_PRODS: 'Recent Products',
});
export const refundStatusMsg = Object.freeze({
  SUCCESS: 'Refund initiated for order',
  FAILURE:
    'Refund could not be completed. Please trigger refund manually from razorpay dashboard',
});

export const OfferTypes = Object.freeze({
  PERCENT: 'percent',
  FLAT: 'flat_discount',
  FREEDELIVERY: 'free_delivery',
  FREEPRODUCT: 'free_product',
});

export const DiscountTypes = Object.freeze({
  ORDERLVL: 'order_level',
  ITEMLVL: 'item_level',
});

export const defaultWaitingTimeForResendOTP = 60;

export const reCAPTCHA_site_key = '6LdVTUgbAAAAAKntFYRY9Y2NmcDq9K-YFRtUK5XH';

export const RESTAURANT_TYPE = {
  BRAND: 'brand',
  OUTLET: 'outlet',
};

export const FulfillmentTime = [
  '9:00-11:00',
  '11:00-13:00',
  '13:00-15:00',
  '15:00-17:00',
  '17:00-19:00',
  '19:00-21:00',
  '21:00-23:00',
];

export const bankStructure = Object.freeze({
  name: '',
  contact: '',
  email: '',
  bank_details: {
    account_type: 'bank_account',
    bank_account: {
      account_number: '',
      ifsc: '',
      name: '',
    },
  },
});

export const upiStructure = Object.freeze({
  name: '',
  contact: '',
  email: '',
  upi_details: {
    account_type: 'vpa',
    vpa: {
      address: '',
    },
  },
});

export const COLOURS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const ANALYTIC_DASHBOARD = {
  PAYMENT_CHART: 'Payment Mode',
  FULFILLMENT_CHART: 'Order Type',
  TOP_PERFORMING_ITEMS_CHART: 'Popular Items',
  ORDER_CHART: 'Number Of Orders',
};

export const BUSINESS_TYPE = {
  RETAIL: 'retail',
  ECOM: 'ecom',
};

export const DELETE_MESSAGE = {
  categories: 'Category',
  items: 'Item',
  variants: 'Variant',
};

export const ORDER_TABS = Object.freeze({
  allOrders: 'All Orders',
  new: 'New',
  accepted: 'Accepted',
  packed: 'Packed',
  shipped: 'Shipped',
  outForDelivery: 'Out For Delivery',
  delivered: 'Delivered',
  pickedUp: 'Picked Up',
  cancelled: 'Cancelled',
});

export const INTIAL_TABS = Object.freeze({
  allOrders: 'All Orders',
  new: 'New',
  accepted: 'Accepted',
  packed: 'Packed',
  shipped: 'Shipped',
  outForDelivery: 'Out For Delivery',
});

export const ORDER_TAB_DATA = Object.freeze({
  ALL: [],
  NEW: [],
  ACCEPTED: [],
  PACKED: [],
  SHIPPED: [],
  OUTFORDELIVERY: [],
  DELIVERED: [],
  PICKEDUP: [],
  CANCELLED: [],
});

export const ORDER_DISPLAY_STATUS = Object.freeze({
  pending: 'Pending',
  accepted: 'Accepted',
  packed: 'Packed',
  shipped: 'Shipped',
  delivered: 'Delivered',
  pickedUP: 'Picked Up',
  cancelled: 'Cancelled',
  outForDelivery: 'Out For Delivery',
});

export const Periodoptions = [
  { value: 0, label: 'Today' },
  { value: 1, label: 'Yesterday' },
  { value: 7, label: 'Last week' },
  { value: 30, label: 'Last 30 days' },
  { value: 60, label: 'Last 60 days' },
];

export const ANALYTIC_OPTIONS = [
  { value: 30, label: 'Last 30 Days' },
  { value: 7, label: 'Last Week' },
  { value: 0, label: 'Today' },
];
export const ANALYTIC_OPTIONS_CONDITION = [
  'Last 30 Days',
  'Last Week',
  'Today',
];
export const PAYMENT_COLOR = ['#3B228F', '#FFB93C'];
export const ORDER_TYPE_COLOR = ['#00C3F9', '#FF6A3B'];

export const PeriodoptionsCondition = [
  'Today',
  'Yesterday',
  'Last week',
  'Last 30 days',
  'Last 60 days',
];

export const orderAction = [
  { value: 'Confirm', label: 'Confirm' },
  { value: 'Cancel', label: 'Cancel' },
];

export const packed = { value: 'Packed', label: 'Packed' };
export const shipped = { value: 'Shipped', label: 'Shipped' };
export const outForDelivery = {
  value: 'Out For Delivery',
  label: 'Out For Delivery',
};
export const delivered = { value: 'Delivered', label: 'Delivered' };
export const pickedUP = { value: 'Picked Up', label: 'Picked Up' };
export const cashDelivered = {
  value: 'Delivered',
  label: 'Collected payment & Delivered',
};
export const cashPickedUP = {
  value: 'Picked Up',
  label: 'Collected payment & Picked Up',
};
export const cencelled = { value: 'CancelOrder', label: 'Cancel Order' };

export const StatusColor = Object.freeze({
  SUCCESS: '#1DC417',
  CANCELLED: '#f33314',
  PENDING: '#F3A61D',
  PACKED: '#522CC8',
  ACCEPTED: '#00A4E0',
});

export const ColorTheme = {
  '#ab5252': { PrimaryColor: '#ab5252', SecondaryColor: '#f9cacd' },
  '#037fff': { PrimaryColor: '#037fff', SecondaryColor: '#e3f1ff' },
  '#43bbcc': { PrimaryColor: '#43bbcc', SecondaryColor: '#d2faff' },
  '#e26042': { PrimaryColor: '#e26042', SecondaryColor: '#ffdfd8' },
  '#556ee6': { PrimaryColor: '#556ee6', SecondaryColor: '#e2e7ff' },
  '#7e24bb': { PrimaryColor: '#7e24bb', SecondaryColor: '#eaccff' },
  '#409434': { PrimaryColor: '#409434', SecondaryColor: '#d3ffcc' },
  '#a5195c': { PrimaryColor: '#a5195c', SecondaryColor: '#ffd2e7' },
  '#3523e2': { PrimaryColor: '#3523e2', SecondaryColor: '#e8e6ff' },
};

export const OffersTableHeadings = {
  'Coupon Name': '20%',
  Description: '40%',
  Status: '10%',
  'Show/Hide': '35%',
};

export const DealsTableHeadings = {
  'Deal Type': '20%',
  Products: '50%',
  'Show/Hide': '30%',
};

export const ProductsTableHeadings = {
  Items: '30%',
  Category: '25%',
  MRP: '10%',
  'Selling Price': '15%',
  'Deal price': '15%',
  Action: '5%',
};

export const DefaultDealTypes = [
  { value: 'Today’s Deals', label: 'Today’s Deals' },
  { value: 'Limited Deals', label: 'Limited Deals' },
  { value: 'Deal of the Day', label: 'Deal of the Day' },
  { value: 'Festive Deals', label: 'Festive Deals' },
  { value: 'Rush Hour Deals', label: 'Rush Hour Deals' },
  { value: 'Mega Deals', label: 'Mega Deals' },
];

export const DefaultOrderTypes = [
  { count: 1, name: 'takeaway', fill: '#00C3F9' },
  { count: 1, name: 'delivery', fill: '#FF6A3B' },
];

export const DefaultPaymentTypes = [
  { count: 1, name: 'cash', fill: '#3B228F' },
  { count: 1, name: 'online', fill: '#FFB93C' },
];

export const DefaultOrderTypes1 = [
  { count: 0, name: 'takeaway', fill: '#00C3F9' },
  { count: 0, name: 'delivery', fill: '#FF6A3B' },
];

export const DefaultPaymentTypes1 = [
  { count: 0, name: 'cash', fill: '#3B228F' },
  { count: 0, name: 'online', fill: '#FFB93C' },
];

export const DownloadQr = Object.freeze({
  QR_Code: 'QR Code',
  POSTER_WITH_OFFERS: 'Poster with offers',
  POSTER_WITHOUT_OFFERS: 'Poster without offers',
  TABLE_CARD: 'Table Card',
});

export const DefaultTextAndTitle = Object.freeze({
  title: `Let’s set up your store!`,
  text: 'Easily add products from our extensive product catalog, and set up in a few minutes!',
  fontSize: '28px',
});

export const TextAndTitleCase2 = Object.freeze({
  title: 'It’s time to promote your store!',
  text: 'Share the store link or QR code, and promote your store!',
  fontSize: '28px',
});

export const TextAndTitleCase3 = Object.freeze({
  title: `You do not have any new orders`,
  text: 'Share the store link or QR code, and promote your store!',
  fontSize: '20px',
});

export const TextAndTitleCase4 = Object.freeze({
  title: `You have new orders`,
  text: '',
  fontSize: '20px',
});

export const SubTextAndTitleCase1 = Object.freeze({
  title: `Add more products`,
  text: 'Easily add products from our extensive product catalog. ',
});
export const SubTextAndTitleCase2and3 = Object.freeze({
  title: 'Manage your store',
  text: 'Easily add products, promote your store!  ',
});

export const mobPageHeader = Object.freeze({
  Dashboard: 'Dashboard',
  Orders: 'Orders',
  order: 'Back to Orders',
  track: 'Back to Order Details',
  Settings: 'Settings',
  Products: 'Products',
  openSearch: 'Products',
  accountSettings: 'Account Settings',
  appSettings: 'App Settings',
  orderSettings: 'Order Settings',
  Catalogue: 'Catalogue',
  'Add Custom Product': 'Add new product',
  'Add New Category': 'Add New Category',
  openSearchCatalogue: 'Catalogue',
  openSearchOrder: 'My Orders',
  Offers: 'Offers',
  coupon: 'Coupons',
  deal: 'Deals',
  'Recently Added Items': 'Recently Added Items',
  editItemPopUp1: 'Recently Added Items',
  FAQ: 'FAQ',
  chooseYourLocation: 'Choose Your Location',
});

export const DropDownNames = Object.freeze({
  BUSINESS_CAT: 'BUSINESS_CAT',
  CATEGORIES: 'CATEGORIES',
  SUB_CATEGORIES: 'SUB_CATEGORIES',
  NEW_PRODUCT: 'NEW_PRODUCT',
});

export const BackgroundGrayPages = ['/offers', '/settings', '/dashboard'];

export const FooterContent =
  'Convert your retail business into a digital one and take your store online with Gupshup.';
